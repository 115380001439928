<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :country-filter.sync="countryFilter"
      :verification-filter.sync="verificationFilter"
      :role-options="roleOptions"
      :country-options="countryOptions"
      :verification-options="verificationOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">
                  {{ $t('addUser') }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        primary-key="id"
        :busy="isLoading"
        :empty-text="$t('noMatchingText')"
        responsive
        show-empty
        :items="items"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Loader -->
        <template #table-busy>
          <table-loader />
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.profile.avatar_url"
                :text="avatarText(data.item.profile.person_name)"
                variant="light-primary"
                :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.profile.person_name }}
            </b-link>
            <small class="text-muted">
              {{ data.item.email }}
            </small>
          </b-media>
        </template>

        <!-- Column: Company -->
        <template #cell(company)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ resolveUserCompany(data.item).companyName }}
          </b-link>
          <small class="text-muted">
            {{ resolveUserCompany(data.item).companyCountry }}
          </small>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ $t(resolveUserRoleText(data.item)) }}
            </span>
          </div>
        </template>

        <!-- Column: Company Details -->
        <template #cell(companyDetails)="data">
          <span
            class="align-text-top"
            :class="`text-${resolveCompanyDetails(data.item).variant}`"
          >
            {{ resolveCompanyDetails(data.item).label }}
          </span>
        </template>

        <!-- Column: Activation -->
        <template #cell(activation)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserActivationVariant(data.item)}`"
            class="text-capitalize"
          >
            {{ resolveUserActivationText(data.item) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              variant="primary"
              size="sm"
              class="d-flex justify-content-center"
              :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">
                {{ $t('edit') }}
              </span>
            </b-button>
            <b-button
              :variant="resolveUserActivationActionVariant(data.item)"
              size="sm"
              class="d-flex justify-content-center ml-1"
              @click="verifyUser(data.item)"
            >
              <feather-icon :icon="resolveUserActivationActionIcon(data.item)" />
              <span class="align-middle ml-50">
                {{ resolveUserActivationActionText(data.item) }}
              </span>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('tableDetails', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, onMounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { defaultRolesOptions } from '@/constants/roles';
import TableLoader from '@/views/tables/TableLoader.vue';
import UsersListFilters from './UsersListFilters.vue';
import useUsersList from './useUsersList';
import UserListAddNew from './UserListAddNew.vue';

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    TableLoader,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false);
    const isLoading = ref(false);

    const verificationOptions = [
      { label: 'Pending', value: '0' },
      { label: 'Active', value: '1' },
    ];

    const {
      fetchUsers,
      verifyUser,
      tableColumns,
      refUserListTable,
      items,
      meta,
      resolveUserRole,
      resolveUserActivation,
      resolveUserActivationVariant,
      resolveUserActivationText,
      resolveCompanyDetails,
      resolveUserRoleText,
      resolveUserActivationActionVariant,
      resolveUserActivationActionText,
      resolveUserActivationActionIcon,
      countryOptions,

      refetchData,
      getCountryById,
      perPage,
      currentPage,
      resolveUserCompany,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      roleFilter,
      countryFilter,
      verificationFilter,
    } = useUsersList();

    onMounted(async () => {
      isLoading.value = true;

      await fetchUsers().finally(() => {
        isLoading.value = false;
      });
    });

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      verifyUser,
      tableColumns,
      refUserListTable,
      items,
      meta,
      resolveUserRole,
      resolveUserActivation,

      refetchData,
      getCountryById,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Filter
      avatarText,

      // UI
      resolveUserActivationVariant,
      resolveUserActivationText,
      resolveCompanyDetails,
      resolveUserRoleText,
      resolveUserCompany,
      resolveUserActivationActionVariant,
      resolveUserActivationActionText,
      resolveUserActivationActionIcon,

      roleOptions: defaultRolesOptions,
      countryOptions,
      verificationOptions,
      roleFilter,
      countryFilter,
      verificationFilter,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
