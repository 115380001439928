<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-close-on-backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('addNewUser') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              :label="$t('email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              :label="$t('personFullName')"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.profile.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required"
          >
            <b-form-group
              :label="$t('phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.profile.phone"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="+1 (917) 543-9876"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Post -->
          <validation-provider
            #default="validationContext"
            name="Post"
            rules="required"
          >
            <b-form-group
              :label="$t('jobTitle')"
              label-for="post"
            >
              <b-form-input
                id="post"
                v-model="userData.profile.post"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('jobTitle')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group
              :label="$t('password')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                type="password"
                :placeholder="$t('password')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password confirmation -->
          <validation-provider
            #default="validationContext"
            name="Confirm password"
            rules="required"
          >
            <b-form-group
              :label="$t('confirmPassword')"
              label-for="password_confirmation"
            >
              <b-form-input
                id="password_confirmation"
                v-model="userData.password_confirmation"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                type="password"
                :placeholder="$t('confirmPassword')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Company name"
            rules="required"
          >
            <b-form-group
              :label="$t('companyName')"
              label-for="company_name"
            >
              <b-form-input
                id="company_name"
                v-model="userData.company.company_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Company address"
            rules="required"
          >
            <b-form-group
              :label="$t('companyAddress')"
              label-for="company_address"
            >
              <b-form-input
                id="company_address"
                v-model="userData.company.address"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Bank account"
            rules="required"
          >
            <b-form-group
              :label="$t('bankAccount')"
              label-for="bank_account"
            >
              <b-form-input
                id="bank_account"
                v-model="userData.company.bank_account"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Bank name"
            rules="required"
          >
            <b-form-group
              :label="$t('bankName')"
              label-for="bank_name"
            >
              <b-form-input
                id="bank_name"
                v-model="userData.company.bank_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Bank address"
            rules="required"
          >
            <b-form-group
              :label="$t('bankAddress')"
              label-for="bank_address"
            >
              <b-form-input
                id="bank_address"
                v-model="userData.company.bank_address"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Bank unp"
            rules="required"
          >
            <b-form-group
              label="Bank UNP"
              label-for="bank_unp"
            >
              <b-form-input
                id="bank_unp"
                v-model="userData.company.unp"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Swift"
            rules="required"
          >
            <b-form-group
              label="Swift"
              label-for="swift"
            >
              <b-form-input
                id="swift"
                v-model="userData.company.swift"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Tax"
            rules="required"
          >
            <b-form-group
              :label="$t('taxNumber')"
              label-for="tax"
            >
              <b-form-input
                id="tax"
                v-model="userData.company.tax_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            name="Country"
            rules="required"
          >
            <b-form-group
              :label="$t('country')"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.profile.country"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                label="label"
                :reduce="country => country.value"
                :clearable="false"
                input-id="country"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Is supplier/buyer roles -->
          <validation-provider
            #default="validationContext"
            name="Roles"
            rules="required"
          >
            <b-form-group
              :label="$t('roles')"
              label-for="roles"
            >
              <b-form-checkbox-group
                id="roles"
                v-model="userData.roles"
                :options="roleOptions"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckboxGroup,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { computed, ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckboxGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      roleOptions: [
        { text: this.$t('buyer'), value: 'buyer' },
        { text: this.$t('supplier'), value: 'supplier' },
      ],
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      profile: {
        name: '',
        country: '',
        phone: '',
        post: '',
      },
      email: '',
      roles: [],
      phone: '',
      password: '',
      password_confirmation: '',
      company: {
        company_name: '',
        bank_account: '',
        bank_name: '',
        bank_address: '',
        unp: '',
        address: '',
        swift: '',
        tax_number: '',
      },
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      const params = new URLSearchParams();
      params.append('email', userData.value.email);
      params.append('profile[person_name]', userData.value.profile.name);
      params.append('profile[country_id]', userData.value.profile.country);
      params.append('profile[phone]', userData.value.profile.phone);
      params.append('profile[post]', userData.value.profile.post);
      params.append('password', userData.value.password);
      params.append('password_confirmation', userData.value.password_confirmation);
      params.append('company[company_name]', userData.value.company.company_name);
      params.append('company[bank_account]', userData.value.company.bank_account);
      params.append('company[bank_name]', userData.value.company.bank_name);
      params.append('company[bank_address]', userData.value.company.bank_address);
      params.append('company[unp]', userData.value.company.unp);
      params.append('company[address]', userData.value.company.address);
      params.append('company[swift]', userData.value.company.swift);
      params.append('company[tax_number]', userData.value.company.tax_number);
      userData.value.roles.forEach(role => params.append('roles[]', role));
      store.dispatch('users/create', params)
        .then(status => {
          emit('refetch-data');
          emit('update:is-add-new-user-sidebar-active', !status);
        });
    };

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData);

    const getCountries = computed(() => store.state.app.availableCountries);
    const countries = computed(() => getCountries.value.map(country => ({ value: country.id, label: country.name })));

    return {
      userData,
      countries,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
